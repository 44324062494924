<template>
  <!--begin::List Widget 1-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0">
    <h3 class="card-title font-weight-bolder text-dark">{{ devName }}</h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <template v-for="(item, i) in devInfoList">
        <!--begin::Item-->
        <div class="d-flex align-items-center mb-10" v-bind:key="i">
          <!--begin::Symbol-->
          <div class="symbol symbol-60 symbol-light-primary mr-5">
            <span class="symbol-label">
              {{item.numb}}
            </span>
          </div>
          <!--end::Symbol-->
          <!--begin::Text-->
          <div class="d-flex flex-column font-weight-bold">
            <div class="text-primary mb-1 font-size-lg">
              {{ item.title }}
            </div>
            <span class="text-muted">
              {{ item.desc }}
            </span>
          </div>
          <!--end::Text-->
        </div>
        <!--end::Item-->
      </template>
    </div>
    <!--end::Body-->
  </div>
  <!--end: List Widget 1-->
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "developer-info-widget",
    props: {
        devName: {
            type: String,
            required: false,
            default: "Jonh Doe"
        },
        devInfoList: {
            type: Array,
            required: false,
            default: function () {
                return [
                    {
                        title: "Apps",
                        desc: "Number of apps",
                        numb: "2"
                    },
                    {
                        title: "All Downloads",
                        desc: "Total",
                        numb: "1000"
                    },
                    {
                        title: "All Users",
                        desc: "Total",
                        numb: "50"
                    },
                    {
                        title: "Rating",
                        desc: "Average of all apps",
                        numb: "4.5"
                    }
                ]
            }
        }
    },
    computed: {
        ...mapGetters(["layoutConfig"])
    }
};
</script>
