<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          APPS
        </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          Including approved, in-progress and rejected 
        </span>
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-borderless table-vertical-center">
          <thead>
            <tr>
              <th class="p-0" style="width: 50px"></th>
              <th class="p-0" style="min-width: 200px"></th>
              <th class="p-0" style="min-width: 100px"></th>
              <th class="p-0" style="min-width: 125px"></th>
              <th class="p-0" style="min-width: 110px"></th>
              <th class="p-0" style="min-width: 75px"></th>
              <th class="p-0" style="min-width: 75px"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in appList">
              <tr v-bind:key="i">
                <td class="pl-0 py-4">
                  <div class="symbol symbol-50 symbol-light mr-1">
                    <span class="symbol-label">
                      <img
                        :src="item.imageUrl"
                        class="h-50 align-self-center"
                        alt=""
                      />
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >
                    {{ item.name }}
                  </a>
                </td>
                <td class="text-right">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    P{{ item.price }}
                  </span>
                  <span class="text-muted font-weight-bold">Price</span>
                </td>
                <td class="text-right">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.rating }}
                  </span>
                  <span class="text-muted font-weight-bold">Rating</span>
                </td>
                <td class="text-right">
                  <span class="label label-lg label-light-primary label-inline">
                    {{ item.status }}
                  </span>
                </td>
                <td class="text-right">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.user }}
                  </span>
                  <span class="text-muted font-weight-bold">User</span>
                </td>
                <td class="text-right">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.downloads }}
                  </span>
                  <span class="text-muted font-weight-bold">Downloads</span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "app-stats-list",
  props: {
    appList: {
      type: Array,
      required: false,
      default: function () {
        return [
          {
            imageUrl: "media/svg/misc/006-plurk.svg",
            name: "Sant Outstanding",
            price: "2",
            rating: 4.0,
            status: "Approved",
            user: 100,
            downloads: 500
          },
          {
            imageUrl: "media/svg/misc/015-telegram.svg",
            name: "Application Development",
            price: "50",
            rating: 0,
            status: "In Progress",
            user: 0,
            downloads: 0
          },
          {
            imageUrl: "media/svg/misc/003-puzzle.svg",
            name: "Payrol Application",
            price: "60",
            rating: 4,
            status: "Approved",
            user: 10,
            downloads: 46
          },
          {
            imageUrl: "media/svg/misc/005-bebo.svg",
            name: "HR Management System",
            price: "100",
            rating: 0,
            status: "Rejected",
            user: 0,
            downloads: 0
          },
          {
            imageUrl: "media/svg/misc/014-kickstarter.svg",
            name: "KTR Mobile Application",
            price: "70",
            rating: 0,
            status: "In Progress",
            user: 0,
            downloads: 0
          }
        ]
      }
    }
  },
  components: {},
  computed: {
    ...mapGetters(["layoutConfig"])
  }
};
</script>
